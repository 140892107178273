import './src/assets/styles/global.scss'
import React from 'react'
import Chat from './src/components/common/chat/Chat'
import { getCookieConsentValue } from 'react-cookie-consent'
import CookiesContainer from './src/components/common/CookiesConsent/CookiesConsent'
import { Amplify } from 'aws-amplify'
import awsconfig from './src/aws-exports'
Amplify.configure(awsconfig)

export const wrapRootElement = ({ element }) => {
    const isCookieAccepted = Boolean(getCookieConsentValue())

    return (
        <>
            <Chat>{element}</Chat>
            {!isCookieAccepted ? <CookiesContainer /> : null}
        </>
    )
}
