import React, { useRef } from 'react'
// @ts-ignore
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

interface IChatProps {
    children: React.ReactNode
}

const Chat = ({ children }: IChatProps) => {
    const tawkMessengerRef = useRef(null)

    return (
        <>
            <TawkMessengerReact
                propertyId={process.env.GATSBY_TAWK_ID}
                widgetId={process.env.GATSBY_WIDGET_ID_TAWK}
                ref={tawkMessengerRef}
            />
            {children}
        </>
    )
}

export default Chat
